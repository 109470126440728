global.$ = global.jQuery = require('jquery');

window.onload = function() {
  document.querySelector('html.preload').classList.remove('preload');
};

// TEMP -- START
jQuery(document).ready(function() {

	jQuery('.temp_nav__trigger').click(function() {
		jQuery('html').toggleClass('temp_nav--open');
	});
	jQuery(document).mouseup(function(e) {
		var container = jQuery('.temp_nav');

		// if the target of the click isn't the container nor a descendant of the container
		if (!container.is(e.target) && container.has(e.target).length === 0) {
			jQuery('html').removeClass('temp_nav--open');
		}
	});

});
// TEMP -- END
